import * as React from "react";

import Square from "./Square";
import { SquareValue } from "./SquareState";

interface BoardProps {};

interface BoardState {
  squares: Array<SquareValue>,
  xIsNext: boolean,
};

export default class Board extends React.Component<BoardProps, BoardState> {

    constructor(props: BoardProps) {
      super(props);
      this.state = {
        squares: Array(9).fill(null),
        xIsNext: true,
      }
    }

    calculateWinner(): SquareValue {
      const lines = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
      ];

      for(const line of lines) {
        const [x, y, z] = line;
        if(
          this.state.squares[x] &&
          this.state.squares[x] == this.state.squares[y] &&
          this.state.squares[x] == this.state.squares[z]) {
            return this.state.squares[x];
        }
      }
      return null;
    }

    render() {
      const winner = this.calculateWinner();
      let status;
      if(winner) {
        status = `Winner is: ${winner}`;
      } else {
        status = `Next player: ${this.state.xIsNext ? "X" : "0"}`;
      }
      return (
        <div>
          <div className="status">{status}</div>
          <div className="board-row">
            {this.renderSquare(0)}
            {this.renderSquare(1)}
            {this.renderSquare(2)}
          </div>
          <div className="board-row">
            {this.renderSquare(3)}
            {this.renderSquare(4)}
            {this.renderSquare(5)}
          </div>
          <div className="board-row">
            {this.renderSquare(6)}
            {this.renderSquare(7)}
            {this.renderSquare(8)}
          </div>
        </div>
      );
    }

    handleClick(index: number) {
      const squares = this.state.squares.slice();
      if(squares[index] || this.calculateWinner()) {
        return;
      }
      squares[index] = this.state.xIsNext ? 'X' : '0';
      this.setState({
        squares: squares,
        xIsNext: !this.state.xIsNext,
      });
    }

    renderSquare(i: number) {
      return <Square
        value={this.state.squares[i]}
        onClick={() => this.handleClick(i)} />;
    }
}